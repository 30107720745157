{
  "name": "frontend-monorepo",
  "version": "9.8.6",
  "license": "MIT",
  "scripts": {
    "ng": "nx",
    "postinstall": "node ./decorate-angular-cli.js",
    "nx": "nx",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "nx run-many --target lint --all",
    "scss-lint": "scss-lint apps -c scss-lint.yml",
    "stylelint": "stylelint **/*.scss",
    "stylelint-fix": "stylelint **/*.scss --fix",
    "e2e": "ng e2e",
    "analyze": "ng run bart:build:production --stats-json && webpack-bundle-analyzer dist/apps/bart/browser/stats.json",
    "affected:apps": "nx affected:apps",
    "affected:libs": "nx affected:libs",
    "affected:build": "nx affected:build",
    "affected:lint": "nx affected:lint",
    "affected:dep-graph": "nx affected:dep-graph",
    "affected": "nx affected",
    "format": "nx format:write",
    "format:write": "nx format:write",
    "format:check": "nx format:check",
    "update": "nx migrate latest",
    "workspace-generator": "nx workspace-generator",
    "dep-graph": "nx dep-graph",
    "dep-graph-export": "nx graph --file=apps/bob/src/app/pages/graph/graph.page.html",
    "help": "nx help",
    "release": "standard-version",
    "compodoc": "npx compodoc -p tsconfig.doc.json --disableGraph --hideGenerator -d apps/bob/src/assets/docs",
    "deploy:demo:staging": "ng run-many --target=deploy --configuration=staging --projects=bart,lisa,patty,winchester,selma --domain=betstarters.cloud --skipInstall",
    "deploy:demo:production": "ng run-many --target=deploy --configuration=production --projects=bart,patty,winchester --domain=betstarters.cloud --skipInstall",
    "bart:extract": "ngx-translate-extract -i ./apps/bart ./libs/bs ./libs/homer -o ./apps/bart/src/assets/i18n/bart.json -m _ --clean --sort --format namespaced-json",
    "bart:dev:ssr": "nx run bart:serve-ssr",
    "bart:serve:ssr": "pm2 start --name bart",
    "bart:build:ssr": "nx bart:build:production && nx run bart:server:production",
    "bart:prerender": "nx run bart:prerender",
    "maggie:extract": "ngx-translate-extract -i ./apps/maggie ./libs/bs ./libs/homer/accounts ./libs/homer/common ./libs/homer/games ./libs/homer/me ./libs/homer/promotions ./libs/homer/smartico -o ./apps/maggie/src/assets/i18n/maggie.json -m _ --clean --sort --format namespaced-json",
    "lisa:dev:ssr": "nx run lisa:serve-ssr",
    "lisa:serve:ssr": "pm2 start --name lisa",
    "lisa:build:ssr": "nx run lisa:build:production && nx run lisa:server:production",
    "lisa:prerender": "nx run lisa:prerender",
    "patty:extract": "ngx-translate-extract -i ./apps/patty ./libs/bs ./libs/marge -o ./apps/patty/src/assets/i18n/patty.json -m _ --clean --sort --format namespaced-json",
    "patty:dev:ssr": "nx run patty:serve-ssr",
    "patty:build": "nx run patty:build",
    "patty:serve:ssr": "cd dist/staging/apps/patty/ && node server/server.mjs",
    "patty:sprite:custom": "svg-sprite -s --symbol-sprite=custom.svg  --symbol-dest=apps/patty/src/assets/icons apps/patty/src/assets/icons/custom/*.svg",
    "winchester:extract": "ngx-translate-extract -i ./apps/winchester ./libs/bs ./libs/krusty -o ./apps/winchester/src/assets/i18n/winchester.json -m _ --clean --sort --format namespaced-json",
    "sprite:brands": "svg-sprite -s --symbol-sprite=brands.svg  --symbol-dest=shared/assets/icons shared/generators/brands/*.svg",
    "sprite:flags": "svg-sprite -s --symbol-sprite=flags.svg  --symbol-dest=shared/assets/icons shared/generators/flags/*.svg",
    "sprite:regular": "svg-sprite -s --symbol-sprite=regular.svg  --symbol-dest=shared/assets/icons shared/generators/regular/*.svg",
    "sprite:solid": "svg-sprite -s --symbol-sprite=solid.svg  --symbol-dest=shared/assets/icons shared/generators/solid/*.svg",
    "sprite:menu": "svg-sprite -s --symbol-sprite=menu.svg  --symbol-dest=shared/assets/icons shared/generators/menu/*.svg",
    "sprite:global": "svg-sprite -s --symbol-sprite=global.svg  --symbol-dest=shared/assets/icons shared/generators/global/*.svg",
    "sprite:sports-categories": "svg-sprite -s --symbol-sprite=sports-categories.svg  --symbol-dest=shared/assets/icons shared/generators/sports-categories/*.svg",
    "sprite:games-categories": "svg-sprite -s --symbol-sprite=games-categories.svg  --symbol-dest=shared/assets/icons shared/generators/games-categories/*.svg",
    "sprite:payments": "svg-sprite -s --symbol-sprite=payments.svg  --symbol-dest=shared/assets/icons shared/generators/payments/*.svg",
    "sprite:social-icons": "svg-sprite -s --symbol-sprite=social-icons.svg  --symbol-dest=shared/assets/icons shared/generators/social-icons/*.svg",
    "sprite:games-providers:black": "svg-sprite -s --symbol-sprite=games-providers-black.svg  --symbol-dest=shared/assets/icons shared/generators/games-providers/black/*.svg",
    "sprite:games-providers:color": "svg-sprite -s --symbol-sprite=games-providers-color.svg  --symbol-dest=shared/assets/icons shared/generators/games-providers/color/*.svg",
    "sprite:games-themes": "svg-sprite -s --symbol-sprite=games-themes.svg  --symbol-dest=shared/assets/icons shared/generators/games-themes/*.svg"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/architect": "^0.1700.6",
    "@angular/animations": "17.1.3",
    "@angular/cdk": "17.1.2",
    "@angular/common": "17.1.3",
    "@angular/compiler": "17.1.3",
    "@angular/core": "17.1.3",
    "@angular/forms": "17.1.3",
    "@angular/localize": "17.1.3",
    "@angular/material": "17.1.2",
    "@angular/platform-browser": "17.1.3",
    "@angular/platform-browser-dynamic": "17.1.3",
    "@angular/platform-server": "17.1.3",
    "@angular/pwa": "17.1.3",
    "@angular/router": "17.1.3",
    "@angular/service-worker": "17.1.3",
    "@angular/ssr": "17.1.3",
    "@compodoc/compodoc": "^1.1.19",
    "@fingerprintjs/fingerprintjs": "^3.4.0",
    "@livechat/widget-angular": "^1.3.2",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@nx/devkit": "17.2.8",
    "@sentry/angular-ivy": "^7.77.0",
    "@smartico/public-api": "^0.0.90",
    "@swc/helpers": "~0.5.2",
    "@tinymce/tinymce-angular": "^7.0.0",
    "angularx-qrcode": "^17.0.0",
    "bootstrap": "^5.3.2",
    "bs-stepper": "^1.7.0",
    "buffer": "^5.6.0",
    "centrifuge": "^3.1.0",
    "chart.js": "^3.9.1",
    "compression": "^1.7.4",
    "cookie-parser": "^1.4.6",
    "es6-crawler-detect": "^3.3.0",
    "express": "^4.18.2",
    "file-saver": "^2.0.5",
    "injection-js": "^2.4.0",
    "ng-lazyload-image": "^9.1.1",
    "ngx-barcode6": "^1.0.24",
    "ngx-multi-pixel": "^2.2.2",
    "ngx-ssrs-reportviewer-v2": "^17.0.0",
    "paymentiq-cashier-bootstrapper": "^1.3.24",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.17.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "sitemap": "^7.1.1",
    "socket.io-client": "^2.5.0",
    "swiper": "^11.1.1",
    "ts-md5": "^1.3.1",
    "tslib": "^2.5.0",
    "xlsx": "^0.17.4",
    "zone.js": "0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.1.3",
    "@angular-devkit/core": "17.1.3",
    "@angular-devkit/schematics": "17.1.3",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "~17.1.3",
    "@angular/compiler-cli": "17.1.3",
    "@angular/language-service": "17.1.3",
    "@colsen1991/ngx-translate-extract": "^8.0.7",
    "@commitlint/cli": "^18.5.0",
    "@commitlint/config-angular": "^14.1.0",
    "@nrwl/tao": "17.2.8",
    "@nx/angular": "17.2.8",
    "@nx/eslint": "17.2.8",
    "@nx/eslint-plugin": "17.2.8",
    "@nx/jest": "17.2.8",
    "@nx/js": "17.2.8",
    "@nx/plugin": "17.2.8",
    "@nx/workspace": "17.2.8",
    "@schematics/angular": "17.1.3",
    "@swc-node/register": "~1.6.7",
    "@swc/cli": "~0.1.62",
    "@swc/core": "~1.3.85",
    "@types/express": "^4.17.17",
    "@types/file-saver": "^2.0.7",
    "@types/google.analytics": "^0.0.42",
    "@types/jest": "^29.4.0",
    "@types/node": "18.16.9",
    "@typescript-eslint/eslint-plugin": "6.9.1",
    "@typescript-eslint/parser": "6.9.1",
    "bufferutil": "^4.0.7",
    "eslint": "8.48.0",
    "eslint-config-prettier": "9.0.0",
    "fs-extra": "^10.0.0",
    "husky": "^4.3.8",
    "jest": "^29.4.1",
    "jest-environment-jsdom": "^29.4.1",
    "jest-preset-angular": "~13.1.4",
    "jsonc-eslint-parser": "^2.1.0",
    "ng-packagr": "17.1.2",
    "nx": "17.2.8",
    "nx-cloud": "^16.0.4",
    "prettier": "2.6.2",
    "standard-version": "^9.3.2",
    "stylelint": "^14.16.1",
    "stylelint-config-recommended": "^6.0.0",
    "stylelint-config-sass-guidelines": "^9.0.1",
    "stylelint-config-standard": "^24.0.0",
    "stylelint-scss": "^4.4.0",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "5.2.2",
    "utf-8-validate": "^5.0.10",
    "webpack-bundle-analyzer": "^4.8.0"
  },
  "commitlint": {
    "extends": [
      "@commitlint/config-angular"
    ]
  },
  "husky": {
    "hooks": {
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS"
    }
  },
  "release": {
    "branches": [
      "+([0-9])?(.{+([0-9]),x}).x",
      "master",
      "feat/menu-v2",
      "next-major",
      {
        "name": "beta",
        "prerelease": true
      },
      {
        "name": "alpha",
        "prerelease": true
      }
    ],
    "plugins": []
  },
  "browser": {
    "crypto": false,
    "https": false
  }
}
